import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRouter } from "next/router"; // Import useRouter
import formatKeyword from "@/features/jobSearch/hooks/formatKeyword";

function FooterSearch({ data, searchData, device, secondHeader }) {
  const [height, setHeight] = useState(200);
  const [loading, setLoading] = useState(false);
  const router = useRouter(); // Use useRouter hook

  const handleRedirect = (e, url) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      router.push(url); // Navigate using Next.js router
    }, 500); // Simulate loading time
  };

  // Use router.events to track route changes and show/hide loading indicator
  useEffect(() => {
    const handleStart = () => setLoading(true);
    const handleComplete = () => setLoading(false);

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  }, [router]);

  const allDesignations = {
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    centerMode: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        bgcolor: (theme) => theme.palette.primary[900],
        borderRadius: "16px",
        p: "20px",
        mt: "32px",
        width: "100%",
        "& br": {
          display: "none",
        },
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        mb={2.5}
        sx={{ flexWrap: { xs: "wrap", md: "nowrap" } }}
      >
        <Typography
          component="h2"
          sx={{
            fontSize: { xs: "14px", md: "20px" },
            fontWeight: "bold",
            mr:2,
          }}
        >
          {secondHeader}
        </Typography>
        <Box>
          <a
            style={{
              margin: "0px",
              lineHeight: "1.235",
              fontSize: "14px",
              fontWeight: "600",
              whiteSpace: "nowrap",
            }}
            href={`/jobs-near-me${
              router?.query?.keyword
                ? `?keyword=${formatKeyword(router?.query?.keyword)}`
                : ""
            }`}
            onClick={(e) =>
              handleRedirect(
                e,
                `/jobs-near-me${
                  router?.query?.keyword
                    ? `?keyword=${formatKeyword(router?.query?.keyword)}`
                    : ""
                }`
              )
            }
          >
            Jobs Near Me
          </a>
          <a
            style={{
              margin: "0px 0px 0px 12px",
              lineHeight: "1.235",
              fontSize: "14px",
              whiteSpace: "nowrap",
              fontWeight: "600",
            }}
            href={`/jobs-by-designations`}
            onClick={(e) => handleRedirect(e, "/jobs-by-designations")}
          >
            All Designations
          </a>
        </Box>
      </Box>
      <Box
        component="nav"
        aria-labelledby="seo-page-footer"
        sx={{
          height: height,
          overflowY: "hidden",
          transition: "all .3s",
          "& .slick-slider": {
            margin: { xs: "0", md: "0 30px" },
          },
          "& .slick-next": {
            top: height === 200 ? "18%" : "50%",
            transition: "all .3s",
            "::after": {
              color: "#1c1b1b",
            },
            "::before": {
              color: "#1c1b1b",
            },
          },
          "& .slick-prev": {
            top: height === 200 ? "18%" : "50%",
            transition: "all .3s",
            "::after": {
              color: "#1c1b1b",
            },
            "::before": {
              color: "#1c1b1b",
            },
          },
        }}
      >
        <Slider {...allDesignations}>
          {Boolean(data?.cities && Array.isArray(data?.cities) && data?.cities?.length > 0) && data?.cities?.map((item, index1) => {
            return (
              <Box
                key={index1}
                className="panel"
                sx={{
                  width: { xs: 290, md: 360 },
                  "& a": {
                    display: "block",
                    mt: 0.2,
                    color: (theme) => theme.palette.gray.main,
                  },
                  mx: 2,
                }}
              >
                <Typography
                  display="block"
                  variant="h4"
                  fontSize={16}
                  fontWeight={600}
                  textTransform="capitalize"
                  mb={1}
                >
                  {item?.cityName}
                </Typography>
                {Boolean(item?.titleList && Array.isArray(item?.titleList) && item?.titleList?.length > 0) && item?.titleList?.length > 0
                  ? item?.titleList?.map((cities, index2) => {
                      return (
                        <React.Fragment key={index2}>
                          <Typography noWrap variant="caption">
                            <a
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "290px",
                                whiteSpace: "nowrap",
                                marginBottom: "10px",
                                display: "flex",
                                alignItems: "center",
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                              onClick={(e) => handleRedirect(e, cities?.slug)}
                            >
                              <i className="icon-next" />
                              &nbsp;{cities?.title}
                            </a>
                          </Typography>
                          <br />
                        </React.Fragment>
                      );
                    })
                  : ""}
              </Box>
            );
          })}
          <Box
            className="panel"
            sx={{
              width: { xs: 180, md: 360 },
              "& a": {
                display: "block",
                fontSize: 13,
                mt: 0.2,
                color: (theme) => theme.palette.gray.main,
              },
              mx: 2,
            }}
          >
            <Typography
              display="block"
              variant="h4"
              fontSize={16}
              fontWeight={600}
              mb={1}
            >
              All Jobs
            </Typography>
            {Boolean(data?.default && Array.isArray(data?.default) && data?.default?.length > 0) && data?.default?.length > 0
              ? data?.default?.map((cities, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Typography noWrap variant="caption" key={index}>
                        <a
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "290px",
                            whiteSpace: "nowrap",
                            marginBottom: "10px",
                            cursor: "pointer",
                          }}
                          onClick={(e) => handleRedirect(e, cities?.slug)}
                        >
                          <i className="icon-next" />
                          &nbsp;{cities?.title}
                        </a>
                      </Typography>
                      <br />
                    </React.Fragment>
                  );
                })
              : ""}
          </Box>
        </Slider>
      </Box>
      <Box textAlign="center" my={2}>
        <Box
          sx={{
            borderRadius: 8,
            bgcolor: "#ffffff",
            height: 25,
            width: "fit-content",
            display: "flex",
            alignItems: "center",
            color: "#2a5798",
            mx: "auto",
            p: 2,
            cursor: "pointer",
          }}
          gap={1}
          onClick={() => (height === 200 ? setHeight("100%") : setHeight(200))}
        >
          {height === 200 ? "Show More" : "Show Less"}{" "}
          {height === 200 ? (
            <i
              className="icon-arrow-down"
              style={{ fontSize: 11, marginLeft: 3, transition: "all .3s" }}
            />
          ) : (
            <i
              className="icon-arrow-down"
              style={{
                fontSize: 11,
                marginLeft: 3,
                transform: "rotate(180deg)",
                transition: "all .3s",
              }}
            />
          )}
        </Box>
      </Box>
      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "white",
            zIndex: 9999,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

export default FooterSearch;
